import { Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Widget } from "../components/Widget";
import { AutoResizeTextarea } from "../components/AutoResizeTextarea";
import { getSettings, updateSetting } from "../api/settings";
import { useEffect, useState } from "react";
import { Setting } from "../types/Setting";

export const SettingsWidget = () => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState('');

  const loadSettings = async () => {
    const data = await getSettings();
    setSettings(data)
  }

  const handleChange = (id: number, value: string) => {
    setSettings((currentValue) => currentValue.map((setting) => setting.id !== id ? setting : { ...setting, value }));
  };

  const settingUpdate = async (name: string) => {
    await updateSetting({ name, value: editingValue });
    setEditingId(null);
    setEditingValue('');
    loadSettings();
  }

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <Widget className='p-3'>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h2 className='h3 m-0 text-secondary'>Settings</h2>

        <div className='d-flex gap-2'>
        </div>
      </div>

      <ul>
        {settings.map(({ id, name, value, type, placeholder, description, symbol_after }) => (
          <li className='list-unstyled p-0 m-0' key={id}>
            <InputGroup
              className='mb-3'
            >
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">{description}</Tooltip>}
              >
                <InputGroup.Text id={name} className='w-25'>{name}</InputGroup.Text>
              </OverlayTrigger>

              <AutoResizeTextarea
                placeholder={placeholder}
                type={type}
                value={id !== editingId ? value?.toString() || '' : editingValue}
                onChange={(e) => id !== editingId ? handleChange(id, e.target.value) : setEditingValue(e.target.value)}
                disabled={id !== editingId}
                autoFocus={true}
              />
              {symbol_after && <InputGroup.Text id={name}>{symbol_after}</InputGroup.Text>}
              {id !== editingId && (
                <Button
                  style={{ width: '81.5px' }}
                  type='button'
                  onClick={() => {
                    setEditingValue(value?.toString() || '');
                    setEditingId(id);
                  }}
                >
                  <i className='bi bi-pen'></i>
                </Button>
              )}
              {id === editingId && (
                <>
                  <Button
                    type='button'
                    variant='success'
                    onClick={() => settingUpdate(name)}
                  >
                    <i className='bi bi-check-lg'></i>
                  </Button>
                  <Button
                    type='button'
                    variant='danger'
                    onClick={() => {
                      setEditingId(null);
                      setEditingValue('');
                    }}
                  >
                    <i className='bi bi-x-lg'></i>
                  </Button>
                </>
              )}
            </InputGroup>
          </li>
        ))}
      </ul>
    </Widget>
  );
}