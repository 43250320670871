import React from 'react';
import { EnvWidget } from '../widgets/EnvWidget';
import { SettingsWidget } from '../widgets/SettingsWidget';


const Settings: React.FC = () => {
 

  return (
    <>
      <SettingsWidget />
      <EnvWidget />
    </>
  )
};

export default Settings;
