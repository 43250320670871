import { client } from './client';

export const getSettings = async () => {
  const response = await client.get('/settings');
  return response.data;
}

export const updateSetting = async (body: { name: string, value: string | number | boolean }) => {
  const response = await client.post('/settings', body);
  return response.data;
}
